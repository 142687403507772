/**
 * @author Juan Rico
 * 
 * @module Home
 * 
 * @description Componente principal de la página Home. Este componente
 * permite al usuario seleccionar entre diferentes simulaciones o acceder
 * a informes, con una interfaz responsive y adaptable para móviles.
 * 
 * 
 */

import React, { useState } from 'react'; // Importa React y hooks.
import { Link } from 'react-router-dom'; // Para linkear hacia otra ruta
import './home.css';  // importacion de la hoja de estilos para esta pagina
import Header from '../../components/Header/Header'; // Componente de la cabezera de la pagina.
import { 
    iconCompresiones, 
    iconDea, 
    iconMonitor, 
    iconNoResponde, 
    iconNotas 
} from '../../helpers/ImagesCache'; //Iconos especificos cargados en cache por el desarrolador
import NSCardButton from '../../components/NSCardButton/NSCardButton'; // Componente para botones de tipo card
import SimpleTitle from '../../components/SimpleTitle/SimpleTitle'; // Componente para titulos simples

/**
 * Componente Home
 * 
 * @component
 * 
 * @description Este componente muestra la página principal, permitiendo la selección
 * de simulaciones avanzadas, básicas y OVACE, así como la navegación a informes.
 * 
 * @returns {JSX.Element} Retorna el componente Home que renderiza la interfaz de la página principal.
 */
const Home = () => {
    
    return (
        <div className='body overflow-hidden right-[auto] left-[auto] flex flex-col bg-white  h-[100vh]  max-w-[1024px] items-center justify-center w-[100vw] overflow-hidden'>
            <Header options={{}} minimal={true} />
            <div className='p-4 flex flex-col flex-1 bg-[#eaf7f5] flex w-full items-center gap-5'>
                <SimpleTitle text='¿Qué vas a simular?' />
                <div className='flex gap-2 w-full w-10/12'>
                    <Link className='w-4/12' to='/advanced'>
                        <NSCardButton
                            options={{
                                title: 'Soporte vital avanzado',
                                icon: iconMonitor,
                            }}
                        />
                    </Link>
                    <Link className='w-4/12' to='/basic'>
                        <NSCardButton
                            options={{
                                title: 'Soporte vital básico + DEA',
                                icon: iconCompresiones,
                                iconOptional: iconDea,
                            }}
                        />
                    </Link>
                    <Link className='w-4/12' to='/ovace'>
                        <NSCardButton
                            options={{
                                title: 'OVACE',
                                icon: iconNoResponde
                            }}
                        />
                    </Link>
                </div>
                <Link className='w-10/12' to='/reports'>
                    <NSCardButton
                        options={{
                            title: 'Informes',
                            icon: iconNotas,
                            horizontal: true
                        }}
                    />
                </Link>
            </div>
        </div>
    );
};

export default Home;