import React, { useEffect, useState } from "react"  // Importa React y hooks.

const Loader = ({ status = false , text = 'Cargando...' }) =>{ 
    const [ isLoading, setIsLoading ] = useState( status )

    useEffect(() => {
        setIsLoading(status)
    },[ status ])
    return (
        <>
            {
                isLoading &&
                <div className='w-full h-full text-xl fixed top-0 left-0 bg-gray-500/20 z-[100000] flex justify-center items-center'>
                    <div className='bg-white p-10 rounded flex flex-col items-center font-bold'> 
                        <span>{text}</span> 
                    </div> 
                </div>
            }
        </>
    ) 
}

export default Loader