/* eslint-disable */
//lista de constantes
export const BASEURLAPI = 'https://simularcp.ninjastudio.dev/api/v1'
export const PRIMARY_COLOR = '#2aac9c'
export const SECONDARY_COLOR = '#eaf7f5'
export const THIRD_COLOR = '#f9fafb'

export const OptionsRitmoECG = [
  'Asistolia',
  'Actividad eléctrica sin pulso',
  'Fibrilación ventricular',
  'Taquicardia ventricular sin pulso',
  'Taquicardia ventricular con pulso',
  'Torsaide de pointes',
  'Bloqueo de 1er grado',
  'Bloqueo de 2º Mobitz I',
  'Bloqueo de 2º Mobitz II', 
  'Bloque de 3er grado',  
  'Taquicardia supraventricular',
  'Ritmo sinusal',  
]


const CausaParadaH = [
  'Hipoxia',
  'Hipovolemia',
  'Hipo-hiperpotasemia / Metabolicas',
  'Hipo-hipertemia', 
]

const CausaParadaT = [
  'Tombosis coronaria o pulmonar',
  'Neumotorax a tension',
  'Taponamiento cardiaco',
  'Toxicos', 
]

const Tecnicas = [
  'Canula de guedel',
  'Aspiracion de secreciones',
  'Transfusion de hemoderivados',
  'Calentamiento paciente', 
  'Enfriamiento paciente',
  'Drenaje pleural',
  'Trombolisis',
  'Angiografía coronaria'
]

const Farmacos = [
  'Bicarbonato',
  'Cloruro de calcio',
  'Gluconato de calcio',
  'Naloxona',
  'Flumazenilo',
  'Insulina rapida',
  'Atropina',
  'Sulfato de magnesio' 
]

export const OptionsCausaParada = [ CausaParadaH, CausaParadaT ]

export const OptionsTecnicasFarmacos = [Tecnicas, Farmacos]



export const TimelineDefault = { 
    name:'simularcp-' + (+new Date()),
    date:+new Date(),
    history:[],
    startCase : null,  
    stopCase : null,
    PCR:[],
    RCE:[],
    isSafeScene:false,
    shoutedHelp:false,
    consistency:null,
    notRespond:null,
    openAirWay:[],
    checkVentilation:[],
    notifiesEmergencyTeam:null,
    lateralSafetyPosition:null,
    RCP:[],
    DEA:[],
    compressions:[],
    vents:[],
    defibrillation:[],
    adrenalin:[],
    amiodarone:[],
    naloxone:[],
    diazepam:[],
    vascularAccess:null,
    otDevice:null,
    monitor:[], 
    complete:false,
    conscious:true,
    toser:null,
    backBlows: [],
    compressionsAbs: [],
    compressionsThorax: [],
    basicVitalSupport:null,
    ECG:[],
    CausaT: [],
    CausaH: [],
    Tecnicas:[],
    Farmacos:[],
    Notas:[]
}